import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.displayAddKeywords();
  }

  hideAllConditions() {
    let addConditions = document.querySelectorAll(".add-condition");
    let addConditionIcons = document.querySelectorAll(".add-condition-icon");

    addConditions.forEach(condition => {
        condition.style.display = "none";
    });
    addConditionIcons.forEach(icon => {
      icon.querySelector(".add-condition-button").style.background = "#f9f9f9";
    });
  }

  displayAddKeywords() {
    let addConditionIcons = this.element;
    let addConditions = this.element.querySelector(".add-condition");
    addConditionIcons.addEventListener("click", (event) => {
      event.stopPropagation();
      if (addConditions.style.display == "block" || addConditions.style.display == "") {
        addConditions.style.display = "none";
        addConditionIcons.querySelector(".add-condition-button").style.background = "#f9f9f9";
      } else{
        this.hideAllConditions();
        addConditions.style.display = "block";
        addConditionIcons.querySelector(".add-condition-button").style.background = "#FFFFFF";
      }
    });

    addConditions.addEventListener("click", (event) => {
        event.stopPropagation();
      });

    document.addEventListener("click", () => {
      this.hideAllConditions();
    });
  }
}
